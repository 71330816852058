import React, { useState } from "react";

import { makeStyles } from 'tss-react/mui';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import NoDataChart from "components/common/NoDataChart";
import SwitchOptions from "../SwitchOptions";
import SwitchOptionsDropdown from "../SwitchOptionsDropdown";
import ExportButton from "components/common/ExportButton";

import KpiContainer from "../KpiContainer";
import withKpiData from "utils/WithKpiData";
import "./index.scss";
import { useGetChartHeight } from "utils/hooks";

const areaconfig = (data, height, width) => {
  const areaSeries = data.series.filter((item) => item.type ==="area")
  const series = areaSeries.map((item) => {
    return {
      ...item,
      data: item.data.map((el) => el.y)
    };
  });
  const maxValue = areaSeries.length > 0 && areaSeries[0].data && areaSeries[0].data[0] && areaSeries[0].data[0].u === "%" ? 100 : Math.max(...series.map((item) => Math.max(...item.data)));
  return {
    chart: { type: "area", height, width },
    title: null,
    xAxis: { categories: data.date },
    yAxis: [
      { title: { text: data.title }, min: 0, max: maxValue, gridLineWidth: 0 },
      { title: { text: null }, visible: false, gridLineWidth: 0 },
    ],
    plotOptions: {
      area: {
        stacking: "normal",
        lineWidth: 0,
        marker: {
          lineWidth: 0,
          enabled: false,
        },
      },
    },
    tooltip: {
      pointFormat: data.tooltip && data.tooltip.pointFormat,
      split: true,
    },
    series: data.series,
  };
};

const pieconfig = (data, height, width) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height,
      width,
    },
    title: null,
    tooltip: data.tooltip,
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f}%",
          style: {
            width: "80px",
            height: "80px",
          },
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        data: data.series,
      },
    ],
  };
};

const areaPieStyles = makeStyles()({
  pie: {
    width: "50%",
  },
  area: {
    width: "50%",
    textAlign: "center",
  },
  alert: {
    position: "relative",
    alignItems: "center",
    width: "5%",
    padding: "0px 10px",
    minHeight: 48,
  },
  container: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    alignContent: "space-between",
  },
  chartsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 50%)",
  },
});

const AreaPieChart = (props) => {
  const {
    componentName,
    data,
    requestHandler: fetchAreaPieChatData,
    gridInformation,
    setComponentFilters,
  } = props;
  const [selectedlocalPeriod, setSelectedlocalPeriod] = useState("");
  const { classes: areaPieStyle } = areaPieStyles();

  const subtractedHeight = () => {
    // chart height
    let result = 0;
    if (data.switch_options_dropdown || data.switch_options) result -= 45;
    return result;
  };

  // ref to get KpiContainer children ref information
  const [childRef, chartHeight, chartWidth] = useGetChartHeight(
    gridInformation,
    subtractedHeight()
  );


  const requestHandler = (apiPayload) => {
    fetchAreaPieChatData(undefined, apiPayload);
  };

  const handlePeriodChange = (value) => {
    setComponentFilters("period", value);
    requestHandler({ period: value });
  };

  const handleSwitchOptionChange = (value) => {
    setComponentFilters("switch_option", value);
    requestHandler({ switch_option: value });
  };

  return (
    <KpiContainer
      title={data.title}
      ref={childRef}
      footer={
        data.export && <ExportButton table={data} component={componentName} />
      }
    >
      {((!data.pie || (data.pie && !data.pie.series.length)) && (!data.area || (data.area && !data.area.series.length))) ? (
        <NoDataChart />
      ) : (
        <div className={`full-height ${areaPieStyle.container}`}>
          {data.switch_options_dropdown ? (
            <div className="chart-wrap__input">
              <SwitchOptionsDropdown
                value={selectedlocalPeriod}
                onChange={handleSwitchOptionChange}
                switchOptions={data.switch_options_dropdown}
              />
            </div>
          ) : null}
          { data.switch_options ? (
            <div className="chart-wrap__input">
              <SwitchOptions
                value={selectedlocalPeriod}
                onChange={handlePeriodChange}
                switchOptions={data.switch_options}
              />
            </div>
          ) : null}
          <div className={areaPieStyle.chartsContainer}>
            {(data.pie && data.pie.series.length)  ? <HighchartsReact
              highcharts={Highcharts}
              options={pieconfig(data.pie || {}, chartHeight, chartWidth / 2)}
            /> : <NoDataChart />}
            {(data.area && data.area.series.length)  ? <HighchartsReact
              highcharts={Highcharts}
              options={areaconfig(data.area || {}, chartHeight, chartWidth / 2)}
            /> : <NoDataChart />}
          </div>
        </div>
      )}
    </KpiContainer>
  );
};

export default withKpiData(AreaPieChart);
