export const getRandomChar = () => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    }

export const getRandomHeight = () => {
    return Math.floor(Math.random() * 100) + 300;
}

export const getHumanReadableDate = (timestamp: number, lang:string = "fr") => {
    const date = timestamp ? new Date(timestamp) :  new Date();
    return date.toLocaleDateString(lang) + ' ' + date.toLocaleTimeString(lang);
}
// Get the time difference between two dates in a human-readable format
export const getTimeDifference = (previous: number) => {

    const current = new Date();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    const elapsed = current.getTime() - previous;
    const yearsCount = Math.round(elapsed/msPerYear );

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }
    else if (!isNaN(yearsCount)) {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }
    return 'just now';
}
export const containsMarkdown : (text:string) => boolean = (text) => {
    // Define some common Markdown patterns
    const markdownPatterns = [
      /\*\*(.*?)\*\*/g,        // Bold (**bold**)
      /\*(.*?)\*/g,            // Italic (*italic*)
      /__(.*?)__/g,            // Bold (__bold__)
      /_(.*?)_/g,              // Italic (_italic_)
      /\[.*?\]\(.*?\)/g,       // Links [text](url)
      /\#\s.*$/gm,             // Headings # Heading
      /\`\`\`[\s\S]*?\`\`\`/g, // Code blocks ```code```
      /\`.*?\`/g,              // Inline code `code`
      /\-\s.*$/gm,             // Lists - item
      /\>\s.*$/gm,             // Blockquotes > quote
      /\!\[.*?\]\(.*?\)/g      // Images ![alt](src)
    ];
  
    // Check if any of the patterns match the text
    return markdownPatterns.some((pattern) => pattern.test(text));
  }
  

  export const groupDates = (sessions: any[]) => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    
    const startOfLastWeek = new Date(startOfWeek);
    startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);
    const startOfLastWeekTimestamp = startOfLastWeek.getTime()/1000;

    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).getTime()/1000;

    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1).getTime()/1000;

    const startOfYear = new Date(today.getFullYear(), 0, 1).getTime()/1000;

    const groups: { thisWeek: Date[], lastWeek: Date[], lastMonth: Date[], lastYear: Date[] } = {
        thisWeek: [],
        lastWeek: [],
        lastMonth: [],
        lastYear: []
    };

    sessions.forEach(session => {
        const date = session.created;
        if (date >= startOfWeek) {
            groups.thisWeek.push(session);
        } else if (date >= startOfLastWeekTimestamp && date < startOfWeek) {
            groups.lastWeek.push(session);
        } else if (date >= startOfLastMonth && date < startOfMonth) {
            groups.lastMonth.push(session);
        } else if (date >= new Date(today.getFullYear() - 1, 0, 1) && date < startOfYear) {
            groups.lastYear.push(session);
        }
    });

    return groups;
}