import React, { useEffect, useState } from "react";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Highcharts from "highcharts/highcharts.js";
import Grid from '@mui/material/Grid';
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import NoDataChart from "components/common/NoDataChart";
import SwitchOptions from "../SwitchOptions";
import SwitchOptionsDropdown from "../SwitchOptionsDropdown";
import SwitchMultiOptionsDropdown from "../SwitchMultiOptionsDropdown";
import ExportButton from "components/common/ExportButton";
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import withKpiData from "utils/WithKpiData";
import KpiContainer from "../KpiContainer";
import "./index.scss";
import { useGetChartHeight } from "utils/hooks";

highchartsMore(Highcharts);
HighchartsHeatmap(Highcharts);

const useStyles = makeStyles()(() => ({
  filterRightContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterLeftContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  right: {
    marginLeft: 'auto'
  }
}));

const UniversalChart = (props) => {
  const {
    component,
    constructorType,
    componentName,
    HighchartsComponent,
    data,
    translate,
    requestHandler: fetchUniversalChatData,
    gridInformation,
  } = props;
  const [nodata, setNodata] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionDropDown, setSelectedOptionDropDown] = useState(null);
  const [selectedMultiOption, setSelectedMultiOption] = useState(null);
  const [selectedLeftMultiOption, setSelectedLeftMultiOption] = useState({});
  const [priceRangeValue, setPriceRangeValue] = useState();
  const { classes } = useStyles();

  const subtractedHeight = () => {
    // chart height
    let result = 0;
    if (
      data.switch_option_multiple ||
      data.switch_options_dropdown ||
      data.switch_options ||
      data.step_size
    )
      result -= 51;

    return result;
  };

  // ref to get KpiContainer children ref information
  const [childRef, chartHeight, chartWidth] = useGetChartHeight(
    gridInformation,
    subtractedHeight()
  );

  useEffect(() => {
    if (data.switch_option_multiple)
      setSelectedMultiOption(
        data.switch_option_multiple.values
          .filter((el) => el.selected === true)
          .map((el) => el.value)
      );
    if (data.switch_options) {
      setSelectedOption(
        data.switch_options.find((el) => el.selected === true)
          ? data.switch_options.find((el) => el.selected === true).value
          : null
      );
    }
    if (data.switch_options_dropdown) {
      setSelectedOptionDropDown(
        data.switch_options_dropdown.find((el) => el.selected === true)
          ? data.switch_options_dropdown.find((el) => el.selected === true).value
          : null
      );
    }
    setPriceRangeValue(data.step_size);
    if (data.left_switch_options_dropdowns) {
      let left_switch_options_dropdowns = {}
      Object.keys(data.left_switch_options_dropdowns).forEach((item) => {
        if (data.left_switch_options_dropdowns[item].values) {
          left_switch_options_dropdowns[item] = data.left_switch_options_dropdowns[item].values
            .filter((el) => el.selected === true)
            .map((el) => el.value)
        }
      })
      setSelectedLeftMultiOption(left_switch_options_dropdowns);
    }
    setNodata(false);
    if (!data.chart || !data.chart?.series || !data.chart?.series?.length) {
      setNodata(true);
    }
  }, []);

  const requestHandler = (switch_option, switch_option_multiple, selectedLeftMultiOption, payload= {}) => {
    const apiPayload = {
      switch_option: switch_option ? switch_option : selectedOption,
      switch_option_multiple: switch_option_multiple
        ? switch_option_multiple
        : selectedMultiOption,
      step_size: Number(priceRangeValue),
      ...payload,
    };
    if (selectedLeftMultiOption) {
      apiPayload.left_switch_options_dropdowns = selectedLeftMultiOption;
    }
    fetchUniversalChatData(undefined, apiPayload);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    requestHandler(null, null, null, {
      period: value
    });
  };

  const handleMultiOptionChange = (value) => {
    setSelectedMultiOption(value);
    requestHandler(null, null, null, {
      switch_option_multiple: value
    });
  };

  const handleOptionDropDownChange = (value) => {
    requestHandler(null, null, null, {
      switch_options_dropdown: value
    });
  }

  const handleLeftMultiOptionChange = (key, value) => {
    const newSelectedLeftMultiOption = { ...selectedLeftMultiOption, [key]: value }
    setSelectedLeftMultiOption(newSelectedLeftMultiOption);
    requestHandler(null, null, newSelectedLeftMultiOption);
  }

  return (
    <KpiContainer
      title={data.title}
      ref={childRef}
      footer={
        data.export && <ExportButton table={data} component={componentName} />
      }
    >
      {nodata && <NoDataChart />}
      {!nodata && data.chart && (
        <>
          <Grid container alignItems="center">
            <Grid>
              <div className={classes.filterLeftContainer}>
                {data.left_switch_options_dropdowns && (
                  Object.keys(data.left_switch_options_dropdowns).map((switch_options_dropdowns, key) => {
                    const el = data.left_switch_options_dropdowns[switch_options_dropdowns];
                    return (<div className="chart-wrap__left_input" key={key}>
                      <SwitchMultiOptionsDropdown
                        value={selectedMultiOption}
                        onChange={(value) => handleLeftMultiOptionChange(switch_options_dropdowns, value)}
                        switchOptions={el}
                      />
                    </div>);
                  }))}
              </div>
            </Grid>
            <Grid className={classes.right}>
              <div className={classes.filterRightContainer}>
                {
                  data.switch_option_multiple && (
                    <div className="chart-wrap__input">
                      <SwitchMultiOptionsDropdown
                        value={selectedMultiOption}
                        onChange={handleMultiOptionChange}
                        switchOptions={data.switch_option_multiple}
                      />
                    </div>
                  )
                }
                {
                  data.switch_options_dropdown && (
                    <div className="chart-wrap__input">
                      <SwitchOptionsDropdown
                        value={selectedOptionDropDown}
                        onChange={handleOptionDropDownChange}
                        switchOptions={data.switch_options_dropdown}
                      />
                    </div>
                  )
                }
                {
                  data.switch_options && (
                    <div className="chart-wrap__input block">
                      <SwitchOptions
                        value={selectedOption}
                        onChange={handleOptionChange}
                        switchOptions={data.switch_options}
                      />
                    </div>
                  )
                }
                {
                  data.step_size && (
                    <div className="chart-wrap__input">
                      <div className="universal-chart__price-range">
                        <TextField
                          variant="standard"
                          value={priceRangeValue}
                          onBlur={(e) =>
                            setPriceRangeValue(
                              e.target.value > 0 ? e.target.value : 0
                            )
                          }
                          onChange={(e) => setPriceRangeValue(e.target.value)}
                          id="outlined-number"
                          label={translate.universalChart.priceRange}
                          type="number"
                          className="universal-chart__price-range__input"
                          placeholder={translate.universalChart.priceRange} />
                        <Button
                          onClick={() =>
                            requestHandler(componentName, selectedOption)
                          }
                          color="primary"
                          variant="contained"
                          className="universal-chart__price-range__button"
                        >
                          {translate.buttons.validate}
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div>
            </Grid>
          </Grid>
          <HighchartsReact
            options={{
              ...data.chart,
              chart: {
                ...data.chart.chart,
                height: chartHeight,
                width: chartWidth,
              },
            }}
            highcharts={Highcharts}
          />
        </>
      )
      }
    </KpiContainer >
  );
};

export default withKpiData(UniversalChart);
